import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { COLORS } from '../domainTypes/colors';

export interface IEMotionTheme {
  custom: {
    colors: {
      success: PaletteColor;
      pending: PaletteColor;
      error: PaletteColor;
      secondary: PaletteColor;
      primary: PaletteColor;
      background: PaletteColor;
      default: PaletteColor;
    };
    fontSize: {
      s: number;
      m: number;
      l: number;
      xl: number;
    };
    border: {
      radius: (multiplier?: number) => number;
      color: string;
      standard: string;
    };
  };
}

export const EMOTION_THEME: IEMotionTheme = {
  custom: {
    border: {
      radius: (multiplier = 1) => 3 * multiplier,
      color: 'rgba(0, 0, 0, 0.1)',
      standard: '1px solid rgba(0, 0, 0, 0.1)'
    },
    colors: {
      primary: {
        light: COLORS.blue.blue1,
        main: COLORS.blue.blue6,
        dark: COLORS.blue.blue8,
        contrastText: 'white'
      },
      secondary: {
        light: '#ff8cb3',
        dark: '#c51162',
        main: '#f50057',
        contrastText: 'white'
      },
      success: {
        light: '#f6ffed',
        dark: '#237804',
        main: '#52c41a',
        contrastText: 'white'
      },
      pending: {
        light: '#fffbe6',
        dark: '#d48806',
        main: '#ffd666',
        contrastText: 'white'
      },
      error: {
        light: '#F7CACA',
        dark: '#DB4D4D',
        main: '#DB4D4D',
        contrastText: 'white'
      },
      background: {
        light: '#f5f4f4',
        main: '#f5f4f4',
        dark: '#f5f4f4',
        contrastText: 'black'
      },
      default: {
        light: '#f5f4f4',
        main: '#DDD',
        dark: '#949494a',
        contrastText: 'black'
      }
    },
    fontSize: {
      s: 12,
      m: 14,
      l: 18,
      xl: 24
    }
  }
};
