import { Avatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { UserAvatarWithNameNextToIt } from '../../../components/UserAvatars';
import { usePromise } from '../../../hooks/usePromise';
import { FlexContainer } from '../../../layout/Flex';
import { getUser } from '../../../services/user';
import * as ENV from '../../env.json';

type Props = {
  userId: string;
};

export const AdminUserAvatar: React.FC<Props> = ({ userId }) => {
  const u: { initials: string; color: string } = (ENV.users as any)[userId] || {
    initials: '?',
    color: ''
  };
  return <Avatar style={{ fontSize: 14 }}>{u.initials}</Avatar>;
};

const SYSTEM_USER = {
  displayName: 'Affilimate',
  email: 'support@affilimate.com',
  photoURL: 'https://affilimate.com/favicon.png'
};

const SYSTEM_USER_DOC = {
  id: 'AFFILIMATE',
  collection: '',
  data: SYSTEM_USER
};

export const UserAvatarWithNameNextToItLazy = ({
  userId,
  size = 16,
  className,
  fallbackToSystemUserIfNeeded = true
}: {
  userId: string;
  size?: number;
  className?: string;
  fallbackToSystemUserIfNeeded?: boolean;
}) => {
  const [user, loading] = usePromise(async () => {
    if (!userId) {
      return null;
    }
    return getUser(userId);
  }, [userId]);
  if (loading) {
    return (
      <FlexContainer>
        <Skeleton variant="circle" width={size} height={size} />
        <Skeleton variant="text" width={50} />
      </FlexContainer>
    );
  }

  const u =
    user && user.data
      ? user
      : fallbackToSystemUserIfNeeded
      ? SYSTEM_USER_DOC
      : null;
  return (
    <UserAvatarWithNameNextToIt
      profile={u?.data}
      size={size}
      className={className}
    />
  );
};
