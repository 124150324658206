import { Card } from '@material-ui/core';
import React from 'react';
import { ISegmentCampaign } from '../../../../../../domainTypes/campaigns';
import { Doc } from '../../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../../layout/Canvas';
import { Section } from '../../../../../../layout/Section';
import { Json } from '../../../../../components/Json';
export const Constraints = ({
  campaign
}: {
  campaign: Doc<ISegmentCampaign>;
}) => {
  return (
    <Section>
      <CanvasBar>
        <div>Page Constraints</div>
      </CanvasBar>
      <Card>
        <Json data={campaign.data.pageConstraints} fullWidth />
      </Card>
    </Section>
  );
};
