import { difference } from 'lodash';
import React, { useMemo, useState } from 'react';
import { BooleanComponent } from '../../../../../components/Boolean';
import { CopyId } from '../../../../../components/CopyButton';
import {
  ItemSorter,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import {
  getAppliedLabel,
  MultiSelector,
  MultiSelectorChip,
  MultiSelectorValueOption
} from '../../../../../components/MultiSelector';
import {
  SearchInput,
  toSearchRegexp
} from '../../../../../components/SearchInput';
import { IColumn } from '../../../../../components/Table/Column';
import { IsoDate } from '../../../../../components/Time/IsoDate';
import { Truncated } from '../../../../../components/Truncated';
import { WithHoverIndicator } from '../../../../../components/WithHoverIndicator';
import {
  ISegmentCampaign,
  SegmentCampaignStatus,
  segmentCampaignStatuses
} from '../../../../../domainTypes/campaigns';
import { Doc } from '../../../../../domainTypes/document';
import {
  getApprovalEvent,
  getScheduleEvent
} from '../../../../../features/Campaigns/service/log';
import {
  CampaignStatusIconWithLabel,
  campaignStatusTitle
} from '../../../../../features/Campaigns/service/segment-campaign';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { useStringQueryParam } from '../../../../../routes';
import { SpaceProfileLabel } from '../../../../components/SpaceProfileLabel';
import { CampaignPartnerProfileLabel } from '../../components/CampaignPartnerProfileLabel';
import { useSegmentCampaigns } from '../../service';

type D = Doc<ISegmentCampaign>;

const SORTERS: ItemSorters<D> = {
  id: {
    key: 'id',
    items: {
      sort: (d) => d.id,
      dir: 'asc'
    }
  },
  name: {
    key: 'name',
    items: {
      sort: (d) => d.data.name,
      dir: 'asc'
    }
  },
  status: {
    key: 'status',
    items: {
      sort: [
        (d) => segmentCampaignStatuses.indexOf(d.data.status),
        (d) => d.data.timeframe?.start?.toMillis() || 0
      ],
      dir: 'desc'
    }
  },
  start: {
    key: 'start',
    items: {
      sort: (d) => d.data.timeframe?.start?.toMillis() || 0,
      dir: 'asc'
    }
  },
  end: {
    key: 'end',
    items: {
      sort: (d) => d.data.timeframe?.end?.toMillis() || 0,
      dir: 'asc'
    }
  }
};

const DEFAULT_SORTER = SORTERS.status;

const rowToKey = (d: D) => d.id;

const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => <CopyId id={d.id} />,
    align: 'left',
    width: 100,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'space',
    head: () => 'Space',
    cell: (d) => <SpaceProfileLabel spaceId={d.data.spaceId} />,
    align: 'left',
    width: 180,
    flexGrow: 0
  },
  {
    key: 'partner',
    head: () => 'Partner',
    align: 'left',
    cell: (d) => <CampaignPartnerProfileLabel campaign={d} />,
    width: 250,
    flexGrow: 0
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => (
      <CampaignStatusIconWithLabel
        status={d.data.status}
        labelProps={{ variant: 'body2' }}
      />
    ),
    align: 'left',
    width: 100,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'paused',
    head: () => 'Paused',
    cell: (d) => <BooleanComponent value={d.data.paused} trueIsGood={false} />,
    align: 'center',
    width: 50,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'name',
    head: () => 'Name',
    cell: (c) => (
      <WithHoverIndicator>
        <Truncated title={c.data.name} />
      </WithHoverIndicator>
    ),
    align: 'left',
    width: 100,
    flexGrow: 1,
    sortable: true
  },
  {
    key: 'approved',
    head: () => 'Approved',
    cell: (c) => <IsoDate d={getApprovalEvent(c)?.timestamp} />,
    align: 'left',
    width: 150,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'scheduled',
    head: () => 'Scheduled',
    cell: (c) => <IsoDate d={getScheduleEvent(c)?.timestamp} />,
    align: 'left',
    width: 150,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'start',
    head: () => 'Start',
    cell: (c) => <IsoDate d={c.data.timeframe?.start} />,
    align: 'left',
    width: 150,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'end',
    head: () => 'End',
    cell: (c) => <IsoDate d={c.data.timeframe?.end} />,
    align: 'left',
    width: 150,
    flexGrow: 0,
    sortable: true
  }
];

const SegmentCampaignsTable = ({
  rows,
  sorter,
  sortDirection,
  setSort
}: {
  rows: Doc<ISegmentCampaign>[];
  sorter: ItemSorter<D>;
  sortDirection: 'asc' | 'desc';
  setSort: (args: [ItemSorter<D>, 'asc' | 'desc' | undefined]) => void;
}) => {
  return (
    <RowsRenderer
      variant="contained"
      rows={rows}
      columns={COLUMNS}
      rowToKey={rowToKey}
      rowToHref={(d) => `/campaigns/${d.id}`}
      sorter={sorter}
      sortDirection={sortDirection}
      onHeadClick={(c, d) => setSort([SORTERS[c.key] || DEFAULT_SORTER, d])}
      chunkSize={100}
      rootMargin="400px"
      rowHeight={ROW_HEIGHTS.dense}
      renderHead
      otherProps={undefined}
    />
  );
};

const STATUS_OPTONS: MultiSelectorValueOption<
  SegmentCampaignStatus
>[] = segmentCampaignStatuses.map((s) => ({
  label: (
    <CampaignStatusIconWithLabel status={s} labelProps={{ variant: 'body2' }} />
  ),
  value: s
}));

export const PageCampaignsList = () => {
  const [campaigns] = useSegmentCampaigns();
  const [selectedStatuses, setSelectedStatuses] = useState(
    new Set(difference(segmentCampaignStatuses, ['completed']))
  );
  const statusFilterApplied =
    segmentCampaignStatuses.length !== selectedStatuses.size;

  const [q, setQ] = useStringQueryParam('q');
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const rows = useMemo(() => {
    if (!campaigns) {
      return null;
    }

    const re = toSearchRegexp(q);
    let res = campaigns;
    res = res.filter((c) => selectedStatuses.has(c.data.status));
    res = re
      ? res.filter((campaign) =>
          [campaign.data.name, campaign.id].some((s) => s.match(re))
        )
      : res;
    return res;
  }, [campaigns, q, selectedStatuses]);
  return (
    <Page width="FULL">
      <Section>
        <CanvasBar>
          <SearchInput
            value={q}
            onChange={setQ}
            placeholder="Search by campaign name"
            autoFocus={true}
            width={300}
          />
          <MultiSelector
            value={selectedStatuses}
            onChange={setSelectedStatuses}
            legend="Filter by status"
            options={STATUS_OPTONS}
            allOption={<strong>All</strong>}
            allowFocusing
          >
            <MultiSelectorChip
              isApplied={statusFilterApplied}
              onDelete={() =>
                setSelectedStatuses(new Set(segmentCampaignStatuses))
              }
              label="Filter by status"
              appliedLabel={getAppliedLabel(
                'status',
                statusFilterApplied
                  ? STATUS_OPTONS.filter((s) =>
                      selectedStatuses.has(s.value)
                    ).map((s) => campaignStatusTitle(s.value))
                  : []
              )}
            />
          </MultiSelector>
        </CanvasBar>
        {!rows && <Loader height={500} />}
        {rows && (
          <SegmentCampaignsTable
            rows={rows}
            sorter={sorter || DEFAULT_SORTER}
            sortDirection={dir || DEFAULT_SORTER.items.dir}
            setSort={setSort}
          />
        )}
      </Section>
    </Page>
  );
};
