import { Card } from '@material-ui/core';
import { reverse } from 'lodash';
import React, { useState } from 'react';
import { PaginatorMini } from '../../../../../../components/PaginatorMini';
import { IsoDate } from '../../../../../../components/Time/IsoDate';
import { ISegmentCampaign } from '../../../../../../domainTypes/campaigns';
import { Doc } from '../../../../../../domainTypes/document';
import { CanvasBar } from '../../../../../../layout/Canvas';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../../layout/Flex';
import { Section } from '../../../../../../layout/Section';
import { UserAvatarWithNameNextToItLazy } from '../../../../../components/AdminUserAvatar';
import { DataGrid } from '../../../../../components/DataGrid';

const MAX_PER_PAGE = 15;
export const Log = ({ campaign }: { campaign: Doc<ISegmentCampaign> }) => {
  const logs = reverse(campaign.data.log);
  const [page, setPage] = useState(1);
  const hasMore = logs.length > page * MAX_PER_PAGE;
  const logsToShow = logs.slice((page - 1) * MAX_PER_PAGE, page * MAX_PER_PAGE);
  return (
    <Section>
      <CanvasBar>
        <div>Log</div>
        <PaginatorMini
          page={page}
          hasMore={hasMore}
          onChange={(nextPage) => setPage(nextPage)}
        />
      </CanvasBar>
      <Card>
        <FlexContainerVertical fullWidth spacing={2}>
          <DataGrid
            columnGap={3}
            items={logsToShow.map((l) => [
              l.operation,
              <FlexContainer fullWidth spacing={3}>
                <IsoDate d={l.timestamp} />
                <UserAvatarWithNameNextToItLazy
                  userId={l.userId}
                  fallbackToSystemUserIfNeeded
                />
              </FlexContainer>,
              logs.indexOf(l)
            ])}
          />
        </FlexContainerVertical>
      </Card>
    </Section>
  );
};
