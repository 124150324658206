import { Button, Card } from '@material-ui/core';
import React, { useState } from 'react';
import { InlineLoader, Loader } from '../../../../../../components/Loader';
import { ISegmentCampaign } from '../../../../../../domainTypes/campaigns';
import { Doc } from '../../../../../../domainTypes/document';
import { useErrorLoggerWithLabel } from '../../../../../../hooks/useErrorLogger';
import { CanvasBar } from '../../../../../../layout/Canvas';
import { Centered } from '../../../../../../layout/Centered';
import { FlexContainer } from '../../../../../../layout/Flex';
import { Section } from '../../../../../../layout/Section';
import { Json } from '../../../../../components/Json';
import { useRedisData } from '../../../service';

const HEIGHT = 200;

const RedisData = ({ campaign }: { campaign: Doc<ISegmentCampaign> }) => {
  const [data, loading, error, reload] = useRedisData(campaign.id);
  useErrorLoggerWithLabel('useRedisData', error);
  console.log('Redis Data', data);
  return (
    <>
      <CanvasBar>
        <div>Redis</div>
        <FlexContainer justifyContent="flex-end">
          {data && loading && <InlineLoader />}
          <Button
            variant="contained"
            color="primary"
            onClick={reload}
            disabled={loading}
            size="small"
          >
            Reload
          </Button>
        </FlexContainer>
      </CanvasBar>
      <Card>
        {loading && !data ? (
          <Loader height={HEIGHT} />
        ) : (
          <Json data={data} fullWidth maxHeight={700} />
        )}
      </Card>
    </>
  );
};

export const Redis = ({ campaign }: { campaign: Doc<ISegmentCampaign> }) => {
  const [showRedis, setShowRedis] = useState(false);
  return (
    <Section>
      {showRedis ? (
        <RedisData campaign={campaign} />
      ) : (
        <>
          <CanvasBar>
            <div>Redis</div>
          </CanvasBar>
          <Card>
            <Centered height={HEIGHT}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowRedis(true)}
              >
                Get Redis Data
              </Button>
            </Centered>
          </Card>
        </>
      )}
    </Section>
  );
};
