import { compact } from 'lodash';
import {
  ProductCatalogConnection,
  ProductCatalogConnectionDetails
} from '../../../../../domainTypes/advertiserApp';
import { Doc, generateToDocFn } from '../../../../../domainTypes/document';
import {
  ProductCatalogDescriptor,
  ProductCatalogVisibility
} from '../../../../../domainTypes/productCatalog';
import {
  refreshTimestamp,
  store,
  useMappedLoadingValue
} from '../../../../../services/db';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../../../services/firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener,
  useDocumentListeners
} from '../../../../../services/firecache/documentListener';
import { getAdvertiserAppCurrentUser } from '../../../../services/currentUser';

export const toProductCatalogConnectionsDoc = generateToDocFn<
  ProductCatalogConnection
>();

const productCatalogConnectionsStore = createCollectionListenerStore((key) => {
  const maIds = key.split('|');
  return new CollectionListener(
    store()
      .collection('productCatalogConnectionsV1')
      .where('marketplaceAdvertiserId', 'in', maIds),
    toProductCatalogConnectionsDoc
  );
});

export const useProductCatalogConnections = (maIds: string[]) => {
  const key = maIds.sort().join('|');
  return useCollectionListener(productCatalogConnectionsStore(key));
};

export const createProductCatalogConnection = async (createArgs: {
  marketplaceAdvertiserId: string;
  spaceId: string;
  name: string;
  details: ProductCatalogConnectionDetails;
}): Promise<Doc<ProductCatalogConnection>> => {
  const d = await callFirebaseFunction<Doc<ProductCatalogConnection>>(
    'marketplaceAdvertisers-createProductCatalogConnection',
    createArgs
  );
  d.data.createdAt = refreshTimestamp(d.data.createdAt);
  d.data.updatedAt = refreshTimestamp(d.data.updatedAt);
  return d;
};

export const removeProductCatalogConnection = async (
  marketplaceAdvertiserId: string,
  connectionId: string
) => {
  const { space } = getAdvertiserAppCurrentUser();
  await callFirebaseFunction(
    'marketplaceAdvertisers-removeProductCatalogConnection',
    {
      marketplaceAdvertiserId,
      connectionId,
      spaceId: space.id
    }
  );
};

export const toProductCatalogDescriptorDoc = generateToDocFn<
  ProductCatalogDescriptor
>((d) => {
  d.disabled = !!d.disabled;
  d.visibility = d.visibility || ProductCatalogVisibility.PRIVATE;
  return d;
});

const productCatalogDescriptorStore = createDocumentListenerGetter((key) => {
  return store().collection('productCatalogDescriptorsV1').doc(key);
}, toProductCatalogDescriptorDoc);

export const useProductCatalogDescriptor = (catalogId: string) => {
  return useDocumentListener(productCatalogDescriptorStore(catalogId));
};

export const useProductCatalogDescriptorsByIds = (catalogIds: string[]) => {
  return useMappedLoadingValue(
    useDocumentListeners(
      catalogIds.map((id) => productCatalogDescriptorStore(id))
    ),
    compact
  );
};
