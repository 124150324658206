import { InputProps } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderOptionState,
  createFilterOptions,
  FilterOptionsState
} from '@material-ui/lab';
import { debounce } from 'lodash';
import React, {
  createElement,
  FocusEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react';
import { EMPTY_ARR } from '../../domainTypes/emptyConstants';

const simpleEquality = <T extends unknown>(a: T, b: T) => a === b;
const identity = <T extends unknown>(t: T) => t;

const FULL_WIDTH_STYLE: React.CSSProperties = { width: '100%' };

type SharedProps<T> = {
  label?: React.ReactNode;
  type?: 'text' | 'url';
  fullWidth?: boolean;
  required?: boolean;
  readOnly?: boolean;
  disableClearable?: boolean;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  size?: 'small' | 'medium';
  groupBy?: (options: T) => string;
  inputProps?: InputProps;
  options: T[] | (() => Promise<T[]>);
  getOptionSelected?: (option: T, value: T) => boolean;
  getOptionLabel?: (option: T) => any;
  renderOption?: (
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
  autoFocus?: boolean;
  style?: React.CSSProperties;
  className?: string;
  onFocus?: FocusEventHandler<HTMLElement>;
  onBlur?: FocusEventHandler<HTMLElement>;
  openOnFocus?: boolean;
  disabled?: boolean;
};

export const AutocompleteSingleAsync = ({
  value,
  onChange,
  options,
  getOptionSelected = simpleEquality,
  getOptionLabel = identity,
  renderOption,
  size,
  fullWidth,
  label,
  required,
  readOnly,
  disableClearable,
  openOnFocus,
  autoFocus,
  style,
  className,
  error,
  helperText,
  onFocus,
  onBlur,
  disabled
}: {
  value: string;
  onChange: (nextValue: string) => void;
  options: (search: string) => Promise<string[]>;
} & Omit<SharedProps<string>, 'options'>) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [opts, setOpts] = React.useState<string[]>([]);

  const fetchOptions = useMemo(
    () =>
      debounce(async (search: string) => {
        try {
          setLoading(true);
          const results = await options(search);
          setOpts(results);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          setOpts([]);
        }
      }, 400),
    [options]
  );

  useEffect(() => {
    fetchOptions(inputValue);
  }, [fetchOptions, inputValue]);

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      onChange={(ev: any, nextValue: string | null) => {
        nextValue && onChange(nextValue);
        setOpen(false);
        setInputValue(inputValue);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      openOnFocus={openOnFocus}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={opts}
      loading={loading}
      size={size}
      filterOptions={identity}
      disableCloseOnSelect={true}
      disableClearable={readOnly || disableClearable}
      style={fullWidth ? FULL_WIDTH_STYLE : undefined}
      renderOption={renderOption}
      onInputChange={(ev, v) => setInputValue(v)}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth={fullWidth}
          required={required}
          autoFocus={autoFocus}
          style={style}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            readOnly,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};

export const AutocompleteMultiAsync = <T,>({
  value,
  onChange,
  options,
  getOptionSelected = simpleEquality,
  getOptionLabel = identity,
  renderOption,
  size,
  fullWidth,
  label,
  required,
  readOnly,
  autoFocus,
  style,
  className,
  error,
  helperText,
  onFocus,
  onBlur,
  disabled,
  disableClearable,
  openOnFocus,
  renderTags
}: {
  value: T[];
  onChange: (nextValue: T[]) => void;
  options: (search: string) => Promise<T[]>;
  renderTags?: (
    value: T[],
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode;
} & Omit<SharedProps<T>, 'options' | 'filterOptions'>) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [opts, setOpts] = React.useState<T[]>(value ?? []);

  const fetchOptions = useMemo(
    () =>
      debounce(async (search: string) => {
        try {
          setLoading(true);
          const results = await options(search);
          setOpts(results);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          setOpts([]);
        }
      }, 400),
    [options]
  );

  useEffect(() => {
    fetchOptions(inputValue);
  }, [fetchOptions, inputValue]);

  return (
    <Autocomplete
      multiple
      className={className}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      onChange={(ev: any, nextValue: T[]) => {
        onChange(nextValue);
        setOpen(false);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      openOnFocus={openOnFocus}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={opts}
      loading={loading}
      size={size}
      filterOptions={identity}
      disableCloseOnSelect={true}
      disableClearable={readOnly || disableClearable}
      style={fullWidth ? FULL_WIDTH_STYLE : undefined}
      renderOption={renderOption}
      renderTags={renderTags}
      onInputChange={(ev, v) => setInputValue(v)}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth={fullWidth}
          required={required}
          autoFocus={autoFocus}
          style={style}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            readOnly,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};

export const AutocompleteSingleFreeSolo = ({
  value,
  type = 'text',
  error = false,
  helperText = '',
  onChange,
  onFocus,
  onBlur,
  label,
  fullWidth,
  required,
  readOnly,
  disableClearable,
  openOnFocus,
  size,
  options,
  getOptionSelected = simpleEquality,
  getOptionLabel = identity,
  renderInputLabel,
  renderOption,
  filterOptions,
  autoFocus,
  style,
  className,
  disabled,
  placeholder
}: {
  value: string;
  onChange: (nextValue: string) => void;
  renderInputLabel?: (v: string) => string;
} & SharedProps<string>) => {
  const isAsync = typeof options === 'function';
  const [open, setOpen] = useState(false);
  const [opts, setOpts] = React.useState<string[] | null>(
    readOnly || isAsync ? null : (options as string[])
  );

  useEffect(() => {
    if (!readOnly && open && opts === null && typeof options === 'function') {
      (options as () => Promise<string[]>)().then(setOpts);
    }
  }, [open, opts, options, readOnly]);

  const loading = open && opts === null && !readOnly;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startingValue = useMemo(() => value, []);

  return (
    <Autocomplete
      multiple={false}
      defaultValue={startingValue}
      inputValue={value}
      onInputChange={(ev, v) => {
        console.log(ev, v);
        return onChange(v);
      }}
      open={open}
      freeSolo={true}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      openOnFocus={openOnFocus}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={opts || EMPTY_ARR}
      size={size}
      loading={loading}
      disableClearable={readOnly || disableClearable}
      style={fullWidth ? FULL_WIDTH_STYLE : undefined}
      disabled={disabled}
      className={className}
      filterOptions={filterOptions}
      renderOption={renderOption}
      renderInput={(params) => {
        console.log('params', params);
        const inputProps = renderInputLabel
          ? {
              ...params.inputProps,
              value: renderInputLabel((params.inputProps as any).value || '')
            }
          : params.inputProps;
        return (
          <TextField
            {...params}
            inputProps={inputProps}
            onFocus={onFocus}
            onBlur={onBlur}
            type={type}
            label={label}
            variant="outlined"
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
            required={required}
            autoFocus={autoFocus}
            style={style}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              readOnly,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        );
      }}
    />
  );
};

export const AutocompleteSingle = <T extends unknown>({
  value,
  onChange,
  onFocus,
  onBlur,
  label,
  fullWidth,
  required,
  readOnly,
  openOnFocus,
  size,
  options,
  getOptionSelected = simpleEquality,
  getOptionLabel = identity,
  renderOption,
  filterOptions,
  autoFocus,
  style,
  error,
  helperText,
  className
}: {
  value: T | null;
  onChange: (nextValue: T | null) => void;
} & SharedProps<T>) => {
  const isAsync = typeof options === 'function';
  const [open, setOpen] = useState(false);
  const [opts, setOpts] = React.useState<T[] | null>(
    readOnly || isAsync ? null : (options as T[])
  );

  useEffect(() => {
    if (!readOnly && open && opts === null && typeof options === 'function') {
      (options as () => Promise<T[]>)().then(setOpts);
    }
  }, [open, opts, options, readOnly]);

  const loading = open && opts === null && !readOnly;

  return (
    <Autocomplete
      multiple={false}
      value={value}
      onChange={(ev, nextValue) => onChange(nextValue)}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      openOnFocus={openOnFocus}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={opts || EMPTY_ARR}
      size={size}
      loading={loading}
      disableClearable={readOnly}
      style={fullWidth ? FULL_WIDTH_STYLE : undefined}
      className={className}
      filterOptions={filterOptions}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth={fullWidth}
          required={required}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onBlur={onBlur}
          style={style}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            readOnly,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};

export const AutocompleteMulti = <T extends unknown>({
  value,
  onChange,
  label,
  fullWidth,
  required,
  readOnly,
  openOnFocus,
  disableClearable,
  disableCloseOnSelect,
  size,
  options,
  getOptionSelected = simpleEquality,
  getOptionLabel = identity,
  renderOption,
  filterOptions,
  renderTags,
  autoFocus,
  style
}: {
  value: T[];
  onChange: (nextValue: T[]) => void;
  disableCloseOnSelect?: boolean;
  renderTags?: (
    value: T[],
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode;
} & SharedProps<T>) => {
  const isAsync = typeof options === 'function';
  const [open, setOpen] = useState(false);
  const [opts, setOpts] = React.useState<T[] | null>(
    readOnly || isAsync ? null : (options as T[])
  );

  useEffect(() => {
    if (!readOnly && open && opts === null && typeof options === 'function') {
      (options as () => Promise<T[]>)().then(setOpts);
    }
  }, [open, opts, options, readOnly]);

  const loading = open && opts === null && !readOnly;

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(ev: any, nextValue: T[] | null) => onChange(nextValue || [])}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      openOnFocus={openOnFocus}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={opts || EMPTY_ARR}
      loading={loading}
      size={size}
      filterOptions={filterOptions}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={readOnly || disableClearable}
      style={fullWidth ? FULL_WIDTH_STYLE : undefined}
      renderOption={renderOption}
      renderTags={renderTags}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth={fullWidth}
          required={required}
          autoFocus={autoFocus}
          style={style}
          InputProps={{
            ...params.InputProps,
            readOnly,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};
export const AutocompleteMultiFreeSolo = ({
  value,
  onChange,
  label,
  placeholder,
  fullWidth,
  required,
  readOnly,
  disableClearable,
  disableCloseOnSelect,
  size,
  options,
  getOptionSelected = simpleEquality,
  getOptionLabel = identity,
  renderOption,
  filterOptions,
  renderTags,
  autoFocus,
  openOnFocus,
  style,
  inputProps,
  error,
  helperText,
  onBlur,
  className
}: {
  value: string[];
  onChange: (nextValue: string[]) => void;
  disableCloseOnSelect?: boolean;
  renderTags?: (
    value: string[],
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode;
} & SharedProps<string>) => {
  const isAsync = typeof options === 'function';
  const [open, setOpen] = useState(false);
  const [opts, setOpts] = React.useState<string[] | null>(
    readOnly || isAsync ? null : (options as string[])
  );

  useEffect(() => {
    if (!readOnly && open && opts === null && typeof options === 'function') {
      (options as () => Promise<string[]>)().then(setOpts);
    }
    if (!readOnly && open && typeof options !== 'function') {
      setOpts(options);
    }
  }, [open, opts, options, readOnly]);

  const loading = open && opts === null && !readOnly;

  return (
    <Autocomplete
      multiple
      freeSolo={true}
      className={className}
      value={value}
      onChange={(ev: any, nextValue: string[] | null) =>
        onChange(nextValue || [])
      }
      open={open}
      openOnFocus={openOnFocus}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={opts || EMPTY_ARR}
      loading={loading}
      size={size}
      fullWidth={fullWidth}
      filterOptions={filterOptions}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={readOnly || disableClearable}
      renderOption={renderOption}
      renderTags={renderTags}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          fullWidth={fullWidth}
          required={required}
          autoFocus={autoFocus}
          style={style}
          inputProps={{ ...params.inputProps, ...inputProps } as any}
          InputProps={{
            ...params.InputProps,
            readOnly,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            )
          }}
          error={error}
          helperText={helperText}
          onBlur={onBlur}
        />
      )}
    />
  );
};

type NewOption = { __newOption: true; __inputValue: string };

export const AutocompleteMultiWithCreateOption = <T extends unknown>({
  value,
  onChange,
  label,
  fullWidth,
  required,
  readOnly,
  disableClearable,
  disableCloseOnSelect,
  openOnFocus,
  size,
  options,
  getOptionSelected = simpleEquality,
  getOptionLabel = identity,
  renderOption,
  filterOptions = createFilterOptions<T>(),
  renderTags,
  createDialog,
  autoFocus,
  style
}: {
  value: T[];
  onChange: (nextValue: T[]) => void;
  disableCloseOnSelect?: boolean;
  renderTags?: (
    value: T[],
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode;
  renderOption: (
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  createDialog: React.ComponentType<{
    open: boolean;
    onClose: () => void;
    inputValue: string;
    onCreate: (newValue: T) => void;
  }>;
} & SharedProps<T>) => {
  const [dialogValue, setDialogValue] = useState('');
  const isAsync = typeof options === 'function';
  const [open, setOpen] = useState(false);
  const [opts, setOpts] = React.useState<T[] | null>(
    readOnly || isAsync ? null : (options as T[])
  );

  useEffect(() => {
    if (!readOnly && open && opts === null && typeof options === 'function') {
      (options as () => Promise<T[]>)().then(setOpts);
    }
  }, [open, opts, options, readOnly]);

  const loading = open && opts === null && !readOnly;

  return (
    <>
      <Autocomplete
        multiple
        value={value}
        onChange={(ev: any, nextValue: T[] | null) => {
          console.log('NEXT', nextValue);
          const newOption =
            nextValue && nextValue.find((o) => (o as NewOption).__newOption);
          if (newOption) {
            setDialogValue((newOption as NewOption).__inputValue);
            return;
          }
          onChange(nextValue || []);
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        openOnFocus={openOnFocus}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={opts || EMPTY_ARR}
        loading={loading}
        size={size}
        filterOptions={(options, params) => {
          const filtered = filterOptions(options, params);
          if (params.inputValue !== '') {
            const newOption: NewOption = {
              __inputValue: params.inputValue,
              __newOption: true
            };
            (filtered as any).push(newOption);
          }
          return filtered;
        }}
        disableCloseOnSelect={disableCloseOnSelect}
        disableClearable={readOnly || disableClearable}
        style={fullWidth ? FULL_WIDTH_STYLE : undefined}
        renderOption={(option, state) => {
          if ((option as NewOption).__newOption) {
            return `Create "${(option as NewOption).__inputValue}"`;
          }
          return renderOption(option, state);
        }}
        renderTags={renderTags}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth={fullWidth}
            required={required}
            autoFocus={autoFocus}
            style={style}
            InputProps={{
              ...params.InputProps,
              readOnly,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      {dialogValue &&
        createElement(createDialog, {
          open: true,
          onClose: () => setDialogValue(''),
          onCreate: (nextValue) => {
            setDialogValue('');
            onChange([...value, nextValue]);
          },
          inputValue: dialogValue
        })}
    </>
  );
};
