import Typography from '@material-ui/core/Typography';
import { findLast } from 'lodash';
import React from 'react';
import { Dash } from '../../../components/Table/CountCell';
import {
  ISegmentCampaign,
  SegmentCampaignLogEntry
} from '../../../domainTypes/campaigns';
import { Doc } from '../../../domainTypes/document';
import { formatSmartRelativeDate } from '../../../services/time';
import { SegmentCampaign } from './segment-campaign';

export const FirstLogTimestamp: React.FC<{
  campaign: SegmentCampaign;
  operation: SegmentCampaignLogEntry['operation'];
}> = ({ campaign, operation }) => {
  const entry = campaign.log.find((l) => l.operation === operation);
  if (!entry) return <Dash />;
  return (
    <Typography variant="body2">
      {formatSmartRelativeDate(entry.timestamp)}
    </Typography>
  );
};

export const LastLogTimestamp: React.FC<{
  campaign: SegmentCampaign;
  operation: SegmentCampaignLogEntry['operation'];
}> = ({ campaign, operation }) => {
  const entry = findLast(campaign.log, (l) => l.operation === operation);
  if (!entry) return <Dash />;
  return (
    <Typography variant="body2">
      {formatSmartRelativeDate(entry.timestamp)}
    </Typography>
  );
};

const getLastCampaignEventByType = (
  campaign: Doc<ISegmentCampaign>,
  type: SegmentCampaignLogEntry['operation']
) => {
  return findLast(campaign.data.log, (l) => l.operation === type) || null;
};

export const getApprovalEvent = (campaign: Doc<ISegmentCampaign>) => {
  return getLastCampaignEventByType(campaign, 'approve') || null;
};

export const getScheduleEvent = (campaign: Doc<ISegmentCampaign>) => {
  return getLastCampaignEventByType(campaign, 'schedule') || null;
};
