import firebase from 'firebase/app';
import { Omit } from 'lodash';
import {
  IManualCampaign,
  ISegmentCampaign
} from '../../../domainTypes/campaigns';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import { useCurrentUser } from '../../../services/currentUser';
import {
  LoadingValue,
  setDoc,
  store,
  updateDoc,
  useMappedLoadingValue,
  useMappedLoadingValueSafe
} from '../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../services/firecache/documentListener';
import { FS } from '../../../versions';
import {
  EMPTY_MANUAL_CAMPAIGN,
  ManualCampaign,
  readManualCampaign
} from './manual-campaign';
import {
  createEmptySegmentCampaign,
  readSegmentCampaign,
  SegmentCampaign
} from './segment-campaign';

export const removeCampaign = async (id: string) =>
  store().collection(FS.campaigns).doc(id).delete();

const toManualCampaignDoc = generateToDocFn<IManualCampaign>();

const manualCampaignsStore = (spaceId: string) =>
  store()
    .collection(FS.campaigns)
    .where('spaceId', '==', spaceId)
    .where('type', '==', 'manual');

const manualCampaignsCollectionListener = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(manualCampaignsStore(spaceId), toManualCampaignDoc)
);

export const useManualCampaignsCollection = (spaceId: string) =>
  useCollectionListener(manualCampaignsCollectionListener(spaceId));

export const useManualCampaigns = (): LoadingValue<ManualCampaign[]> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useManualCampaignsCollection(space.id),
    (campaigns) => campaigns.map(({ data }) => readManualCampaign(data))
  );
};

export const saveManualCampaign = async (
  spaceId: string,
  id: string,
  campaign: Omit<IManualCampaign, 'id' | 'spaceId' | 'type'>
) => {
  const campaignToSave: Doc<IManualCampaign> = {
    collection: FS.campaigns,
    id: id,
    data: {
      ...campaign,
      id,
      spaceId,
      type: 'manual'
    }
  };
  return setDoc(campaignToSave);
};

const manualCampaignListener = createDocumentListenerGetter(
  (docId: string) => store().collection(FS.campaigns).doc(docId),
  toManualCampaignDoc
);

export const useManualCampaign = (
  id: string
): LoadingValue<ManualCampaign | null> => {
  return useMappedLoadingValue(
    useDocumentListener(manualCampaignListener(id)),
    (campaign) => {
      if (!campaign) {
        return null;
      }
      return readManualCampaign(campaign.data);
    }
  );
};

export const useManualCampaignWithDefault = (id: string) => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useDocumentListener(manualCampaignListener(id)),
    (campaign) => {
      if (!campaign) {
        return EMPTY_MANUAL_CAMPAIGN(space.id, id);
      }
      return campaign.data;
    }
  );
};

export const toSegmentCampaignDoc = generateToDocFn<ISegmentCampaign>((d) => {
  d.pageConstraints = d.pageConstraints || {
    include: [],
    exclude: []
  };
  return d;
});

const segmentCampaignsStore = (spaceId: string) =>
  store()
    .collection(FS.campaigns)
    .where('spaceId', '==', spaceId)
    .where('type', '==', 'segment');

const segmentCampaignsCollectionListener = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(segmentCampaignsStore(spaceId), toSegmentCampaignDoc)
);

export const useSegmentCampaignsCollection = (spaceId: string) =>
  useCollectionListener(segmentCampaignsCollectionListener(spaceId));

export const useSegmentCampaigns = (): LoadingValue<Doc<SegmentCampaign>[]> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useSegmentCampaignsCollection(space.id),
    (campaigns) =>
      campaigns.map((doc) => ({ ...doc, data: readSegmentCampaign(doc.data) }))
  );
};

const getSegmentCampaignDoc = async (campaignId: string) => {
  const ref = store().collection(FS.campaigns).doc(campaignId);
  const snapshot = await ref.get();
  return toSegmentCampaignDoc(snapshot);
};

export const saveSegmentCampaign = async (
  spaceId: string,
  campaignId: string,
  userId: string,
  campaign: Omit<ISegmentCampaign, 'id' | 'spaceId' | 'type'>
) => {
  const campaignToSave: Doc<ISegmentCampaign> = {
    collection: FS.campaigns,
    id: campaignId,
    data: {
      ...campaign,
      id: campaignId,
      spaceId,
      type: 'segment',
      log: [
        ...campaign.log,
        {
          operation: 'save-draft',
          timestamp: firebase.firestore.Timestamp.now(),
          userId
        }
      ]
    }
  };
  return setDoc(campaignToSave);
};

export const updateFnSegmentCampaign2 = async (
  doc: Doc<ISegmentCampaign>,
  fn: (campaign: ISegmentCampaign) => Partial<ISegmentCampaign>
) => {
  return updateDoc(doc, fn);
};

export const updateFnSegmentCampaign3 = async (
  campaignId: string,
  fn: (campaign: ISegmentCampaign) => Partial<ISegmentCampaign>
) => {
  const doc = await getSegmentCampaignDoc(campaignId);
  return updateFnSegmentCampaign2(doc, fn);
};

const segmentCampaignListener = createDocumentListenerGetter(
  (docId: string) => store().collection(FS.campaigns).doc(docId),
  toSegmentCampaignDoc
);

export const useSegmentCampaign = (
  id: string
): LoadingValue<SegmentCampaign> => {
  return useMappedLoadingValueSafe(
    useDocumentListener(segmentCampaignListener(id)),
    (campaign) => {
      if (!campaign) {
        throw new Error('Campaign not found');
      }
      return readSegmentCampaign(campaign.data);
    }
  );
};

export const useSegmentCampaignWithDefault = (
  campaignId: string
): LoadingValue<SegmentCampaign> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useDocumentListener(segmentCampaignListener(campaignId)),
    (campaign) => {
      if (!campaign) {
        return createEmptySegmentCampaign(space.id, campaignId);
      }
      return readSegmentCampaign(campaign.data);
    }
  );
};
