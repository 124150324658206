import React from 'react';
import { styled } from '../../../emotion';

const DataGridContainer = styled('div')<{
  rowGap?: number;
  columnGap?: number;
}>((p) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr',
  gridColumnGap: p.theme.spacing(p.columnGap ?? 1),
  gridRowGap: p.theme.spacing(p.rowGap ?? 0.5),
  alignItems: 'center'
}));

export const DATA_GRID_SPACER: [string, React.ReactNode] = [
  '',
  <div style={{ height: 16 }} />
];

export const DataGrid = ({
  items,
  rowGap,
  columnGap
}: {
  items: (
    | [string, React.ReactNode]
    | [string, React.ReactNode, string | number]
  )[];
  rowGap?: number;
  columnGap?: number;
}) => {
  return (
    <DataGridContainer rowGap={rowGap} columnGap={columnGap}>
      {items.map(([left, right, key]) => (
        <React.Fragment key={key ?? left}>
          <strong>{left}</strong>
          {right}
        </React.Fragment>
      ))}
    </DataGridContainer>
  );
};
