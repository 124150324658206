import { IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { FlexContainer } from '../../layout/Flex';

export const PaginatorMini = ({
  page,
  hasMore,
  onChange,
  zeroIndexed = false,
  size = 16
}: {
  page: number;
  hasMore: boolean;
  onChange: (nextPage: number) => void;
  zeroIndexed?: boolean;
  size?: number;
}) => {
  const p = zeroIndexed ? page + 1 : page;
  return (
    <FlexContainer>
      <IconButton
        size="small"
        disabled={p === 1}
        onClick={() => onChange(page - 1)}
      >
        <ChevronLeft size={size} />
      </IconButton>
      <Typography variant="body2" color="textSecondary">
        {p}
      </Typography>
      <IconButton
        size="small"
        disabled={!hasMore}
        onClick={() => onChange(page + 1)}
      >
        <ChevronRight size={size} />
      </IconButton>
    </FlexContainer>
  );
};
