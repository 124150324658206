import { useEffect, useState } from 'react';
import { AdvertiserAppCurrenUser } from '../../domainTypes/advertiserApp';

let currentUser: AdvertiserAppCurrenUser | null = null;
type CurrentUserListener = (
  nextCurrentUser: AdvertiserAppCurrenUser | null
) => void;

const listeners: CurrentUserListener[] = [];

export const setAdvertiserAppCurrentUser = (
  nextCurrentUser: AdvertiserAppCurrenUser | null
) => {
  currentUser = nextCurrentUser;
  listeners.forEach((listener) => listener(nextCurrentUser));
};

// This should only be called after setCurrentUser was called with a non-null value!
export const getAdvertiserAppCurrentUser = () => {
  return currentUser!;
};

const listenToCurrentUserChanges = (
  listener: CurrentUserListener
): (() => void) => {
  listeners.push(listener);
  return () => {
    const index = listeners.indexOf(listener);
    if (index !== -1) {
      listeners.splice(index, 1);
    }
  };
};

export const useAdvertiserAppCurrentUser = (): AdvertiserAppCurrenUser => {
  const [
    currentUser,
    setCurrentUser
  ] = useState<AdvertiserAppCurrenUser | null>(getAdvertiserAppCurrentUser());

  useEffect(() => listenToCurrentUserChanges(setCurrentUser), []);
  // Can never be null - as this is just used inside the app,
  // if it ever IS null, then we would immediately move to the Login page anyway
  return currentUser!;
};
